@import 'base.app.styles.scss';

fox-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

body {
  margin: 0;
}

router-outlet {
  display: none;
}

.cdk-drag-preview {
  opacity: 0.85;
  fox-card {
    width: 500px;
  }
}
